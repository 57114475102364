import { Application } from "@hotwired/stimulus";
import * as Turbo from "@hotwired/turbo";
import {
  Colorpicker,
  Detailsmenu,
  Swathbar,
  Unsavedinputs,
  DatetimeFilter,
  ItemPicker,
  SprayCounts,
  IconAnimate,
} from "./components";
import "./css/input.css";

window.Stimulus = Application.start();

Stimulus.register("swathbar", Swathbar);

Stimulus.register("detailsmenu", Detailsmenu);

Stimulus.register("unsavedinputs", Unsavedinputs);

Stimulus.register("colorpicker", Colorpicker);

Stimulus.register("datetimefilter", DatetimeFilter);

Stimulus.register("itempicker", ItemPicker);

Stimulus.register("spraycounts", SprayCounts);

Stimulus.register("iconanimate", IconAnimate);

document.addEventListener("turbo:submit-start", () => {
  document.getElementById("spraySyncLogo").setAttribute("aria-busy", "true");
});
document.addEventListener("turbo:submit-end", () => {
  document.getElementById("spraySyncLogo").setAttribute("aria-busy", "false");
});
document.addEventListener("turbo:click", () => {
  document.getElementById("spraySyncLogo").setAttribute("aria-busy", "true");
});
document.addEventListener("turbo:frame-render", () => {
  document.getElementById("spraySyncLogo").setAttribute("aria-busy", "false");
});
