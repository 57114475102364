import { Controller } from "@hotwired/stimulus"

export default class IconAnimate extends Controller {
    static targets = ["icon"]

    startAnimation(event) {
        this.iconTarget.classList.add("animate-spin")
    }

    connect() {
    }
}