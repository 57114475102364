import { Controller } from "@hotwired/stimulus";

export default class ItemPicker extends Controller {
  static targets = ["itemCheckbox", "itemContainer", "allItemsCheckbox", "itemList", "filterBox"];

  allItems = true;
  itemNameSet = new Set();

  setAllItems(allItems) {
    this.allItems = allItems;
    this.allItemsCheckboxTarget.checked = allItems;
    this.allItemsCheckboxTarget.disabled = allItems;
  }

  updateItemList(itemNameSet) {
    if (itemNameSet.size === 0) {
      this.itemListTarget.innerHTML = "Select";
    } else {
      this.itemListTarget.innerHTML = Array.from(itemNameSet).join(", ");
    }
  }

  updateCheckbox(target) {
    if (target.checked) {
      this.itemNameSet.add(
        target.getAttribute("data-displayname") || target.value
      );
    } else {
      this.itemNameSet.delete(
        target.getAttribute("data-displayname") || target.value
      );
    }
    this.setAllItems(this.itemNameSet.size === 0);
    this.updateItemList(this.itemNameSet);
  }

  onClickItemCheckbox(e) {
    this.updateCheckbox(e.target);
  }

  onClickAllItemsCheckbox(e) {
    this.allItems = false;
    this.itemNameSet = new Set();
    this.allItemsCheckboxTarget.disabled = true;

    this.itemCheckboxTargets.forEach((t) => {
      t.checked = false;
    });

    this.updateItemList(this.itemNameSet);
  }

  initializeFilterBox() {
    if (this.hasFilterBoxTarget) {
      this.filterBoxTarget.addEventListener('input', (e) => {
        e.stopPropagation();
        const value = e.target.value;
        this.itemContainerTargets.forEach((t) => {
          t.style.display = t.innerText.toLowerCase().includes(value.toLowerCase()) ? 'inline-block' : 'none';
        });
      });
    }
  }

  connect() {
    this.itemCheckboxTargets.forEach((t) => {
      this.updateCheckbox(t);
    });

    this.initializeFilterBox();
  }
}
