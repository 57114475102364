import { Controller } from "@hotwired/stimulus";

export default class Unsavedinputs extends Controller {
  static targets = ["submitbutton", "changedTarget"];
  static classes = ["changed"];
  initialValues = new Map();

  inputChanged(e) {
    const hasChanged = this.hasChanged(this.initialValues, this.getInputMap());
    if (hasChanged) {
      e.target.classList.add(...this.changedClasses);
      if (this.hasChangedTargetTarget) {
        this.changedTargetTarget.classList.add(...this.changedClasses);
      }
    } else {
      e.target.classList.remove(...this.changedClasses);
    }
    if (this.hasSubmitbuttonTarget) {
      this.submitbuttonTargets.forEach((e) => {
        e.disabled = !hasChanged;
      });
    }
  }

  hasChanged(map1, map2) {
    for (const [name, value] of map2) {
      if (!map1.has(name) || map1.get(name) !== value) {
        return true;
      }
    }

    return false;
  }

  getInputMap() {
    const inputMap = new Map();
    const selectInputs = this.element.getElementsByTagName("select");
    for (const input of selectInputs) {
      inputMap.set(input.name, input.value);
    }

    const inputs = this.element.getElementsByTagName("input");
    for (const input of inputs) {
      if (input.type === "checkbox") {
        if (input.checked === true) {
          if (inputMap.has(input.name)) {
            inputMap.get(input.name).add(input.value);
          } else {
            inputMap.set(input.name, new Set([input.value]));
          }
        }
      } else {
        inputMap.set(input.name, input.value);
      }
    }

    return inputMap;
  }

  connect() {
    if (this.hasSubmitbuttonTarget) {
      this.submitbuttonTargets.forEach((e) => {
        e.disabled = true;
      });
    }

    this.initialValues = this.getInputMap();
    this.element.addEventListener("input", this.inputChanged.bind(this));
  }
}
