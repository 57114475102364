import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";

export const SPRAY_TYPES = {
  BOOM: {
    id: "BOOM",
    name: "Boom",
    color: "rgb(0,0,255)",
  },
  HOSEREEL_LINE: {
    id: "HOSEREEL_LINE",
    name: "Hose Reel Line",
    color: "rgb(0,255,255)",
  },
  BACKPACK: {
    id: "BACKPACK",
    name: "Backpack",
    color: "rgb(255,0,255)",
    // color: "rgb(0,0,255)",
  },
  HOSEREEL_POINT: {
    id: "HOSEREEL_POINT",
    name: "Hose Reel Point - Weed Popup",
    color: "rgb(255,95,0)",
  },
  HOSEREEL_POINT_A: {
    id: "HOSEREEL_POINT_A",
    name: "Hose Reel Point",
    color: "rgb(255,95,0)",
  },
  FOGGER: {
    id: "FOGGER",
    name: "Fogger",
    color: "rgb(255, 50, 50)",
  },
};
export default class Swathbar extends Controller {
  static values = {
    switches: Array,
    gpsOffset: Number,
    selectedSwitch: Number,
  };
  static targets = ["swathbarContainer"];
  connect() {
    const COLORS = [
      "#e41a1c",
      "#377eb8",
      "#4daf4a",
      "#984ea3",
      "#ff7f00",
      "#ffff33",
      "#a65628",
      "#f781bf",
    ];

    if (this.hasSwitchesValue && this.hasSwathbarContainerTarget) {
      const MAX = Math.abs(d3.max([d3.max(this.switchesValue, (switchV) => {
        return d3.max(switchV, (swathV) => {
          return Math.max(Math.abs(swathV.start), Math.abs(swathV.end));
        });
      }), Math.abs(this.gpsOffsetValue)]) || 1);
      console.log(MAX)
      let Y_EXTENT = d3.extent([0, ...this.switchesValue.flatMap(t => t.map(a => a.y_offset))]);
      Y_EXTENT[0] -= 1
      Y_EXTENT[1] += 1

      const Y_SCALE = d3.scaleLinear(Y_EXTENT, [100, 0])
      const X_SCALE = d3.scaleLinear([MAX * -1, MAX], [0, 100])

      const Y_RECT_HEIGHT_PCT = 100 / ((Y_EXTENT[1] - Y_EXTENT[0]))

      const viz = d3.select(this.swathbarContainerTarget);

      // const gx = viz.append("svg")
      //   .attr("width", "100%")
      //   // .attr("transform", `translate(0,${height - marginBottom})`)
      //   .append("g")

      //   .call(d3.axisBottom(X_SCALE));

      const switchesViz = viz
        .append('svg')
        .attr('id', 'switchesViz')
        .attr("x", "0%")
        .attr("width", "100%")
        .attr("height", "100%")
        .attr("fill", '#fa0')


      switchesViz
        .append("circle")
        .attr("class", "gps")
        .attr("cx", `${X_SCALE(this.gpsOffsetValue)}%`)
        .attr("cy", `${Y_SCALE(0)}%`)
        .attr("r", 25)
        .attr("fill", "sand")


      switchesViz
        .selectAll('line.yScale')
        .data(d3.range(Y_EXTENT[0], Y_EXTENT[1] + 1))
        .join((enter) => {
          enter
            .append('line')
            .attr('class', 'yScale')
            .attr('x1', '0%')
            .attr('x2', '100%')
            .attr('y1', (d, i) => `${Y_SCALE(d)}%`)
            .attr('y2', (d, i) => `${Y_SCALE(d)}%`)
            .attr("stroke", 'black')
            .attr("stroke-width", (d, i) => d === 0 ? 3 : 1)

        })


      switchesViz
        .selectAll('line.xScale')
        .data(d3.range(Math.floor(-1 * MAX), Math.ceil(MAX)))
        .join((enter) => {
          enter
            .append('line')
            .attr('class', 'yScale')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr('x1', (d, i) => `${X_SCALE(d)}%`)
            .attr('x2', (d, i) => `${X_SCALE(d)}%`)
            .attr("stroke", 'black')
            .attr("stroke-width", (d, i) => d === 0 ? 3 : 1)
        })

      let filteredSwitches = this.switchesValue.flat()

      // if (this.hasSwitchesValue) {
      //   filteredSwitches = this.switchesValue[this.selectedSwitchValue]
      // }

      switchesViz
        .selectAll("rect.switch")
        .data(filteredSwitches)
        .join((enter) => {
          enter
            .append("rect")
            .attr("class", 'switch')
            .attr("width", (d, i) => {
              return `${(Math.abs(d.end - d.start) / (MAX * 2)) * 100}%`
            })
            .attr("height", `${Y_RECT_HEIGHT_PCT}%`)
            .attr("xlink:href", (d, i) => `#switch-config-${d.switch_id}`)
            .attr("fill", (d, i) => {
              if (d["spray_type"] === SPRAY_TYPES.BOOM.id) {
                return `${SPRAY_TYPES.BOOM.color}`;
              } else if (
                d["spray_type"] === SPRAY_TYPES.HOSEREEL_POINT.id
              ) {
                return `${SPRAY_TYPES.HOSEREEL_POINT.color}`;
              } else if (d["spray_type"] === SPRAY_TYPES.FOGGER.id) {
                return `${SPRAY_TYPES.FOGGER.color}`;
              } else if (
                d["spray_type"] === SPRAY_TYPES.HOSEREEL_POINT_A.id
              ) {
                return `${SPRAY_TYPES.HOSEREEL_POINT_A.color}`;
              } else if (d["spray_type"] === SPRAY_TYPES.BACKPACK.id) {
                return `${SPRAY_TYPES.BACKPACK.color}`;
              } else {
                return `${SPRAY_TYPES.HOSEREEL_LINE.color}`;
              }
            })
            .attr("fill-opacity", (d, i) => d.switch_id === this.selectedSwitchValue ? 0.6 : 0.1)
            .attr("stroke", (d, i) => {
              if (d["spray_type"] === SPRAY_TYPES.BOOM.id) {
                return `${SPRAY_TYPES.BOOM.color}`;
              } else if (
                d["spray_type"] === SPRAY_TYPES.HOSEREEL_POINT.id
              ) {
                return `${SPRAY_TYPES.HOSEREEL_POINT.color}`;
              } else if (
                d["spray_type"] === SPRAY_TYPES.HOSEREEL_POINT_A.id
              ) {
                return `${SPRAY_TYPES.HOSEREEL_POINT_A.color}`;
              } else if (d["spray_type"] === SPRAY_TYPES.FOGGER.id) {
                return `${SPRAY_TYPES.FOGGER.color}`;
              } else if (d["spray_type"] === SPRAY_TYPES.BACKPACK.id) {
                return `${SPRAY_TYPES.BACKPACK.color}`;
              } else {
                return `${SPRAY_TYPES.HOSEREEL_LINE.color}`;
              }
            })
            .attr("x", (d, i) => {
              return `${X_SCALE(d3.min([d.start, d.end]))}%`
            }
            )
            .attr("y", (d, i) => {
              return `${(Y_SCALE(d.y_offset)) - (Y_RECT_HEIGHT_PCT / 2)}%`
            })

        })
    }
  }
}
