import { Controller } from "@hotwired/stimulus"

export default class Detailsmenu extends Controller {
    static targets = []

    checkShouldClose(e) {
        if (!this.element.contains(e.target)) {
            this.element.open = false
        }
    }

    connect() {
        document.addEventListener('click', this.checkShouldClose.bind(this))
    }

    disconnect() {
        document.addEventListener('click', this.checkShouldClose.bind(this))
    }
}