import { Controller } from "@hotwired/stimulus";
import {
  startOfDay,
  endOfDay,
  format,
  startOfYesterday,
  endOfYesterday,
  setYear,
} from "date-fns";

export default class DatetimeFilter extends Controller {
  static targets = ["startInput", "endInput"];

  // 2022-11-03T19:00
  formatStr = "y-MM-dd'T'HH:mm";

  commitDates(start, end) {
    this.startInputTarget.value = format(start, this.formatStr);
    this.startInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
    this.endInputTarget.value = format(end, this.formatStr);
    this.endInputTarget.dispatchEvent(new Event("input", { bubbles: true }));
  }

  yesterday(event) {
    const start = startOfYesterday(new Date());
    const end = endOfYesterday(new Date());
    this.commitDates(start, end);
  }

  today(event) {
    const start = startOfDay(new Date());
    const end = endOfDay(new Date());
    this.commitDates(start, end);
  }

  allTime(event) {
    const start = startOfDay(setYear(new Date(), 2000));
    const end = endOfDay(new Date());
    this.commitDates(start, end);
  }

  connect() {}

  disconnect() {}
}
