import { Controller } from "@hotwired/stimulus";
import * as d3 from "d3";
import { SPRAY_TYPES } from "./Swathbar";

export default class SprayCounts extends Controller {
  static values = {
    spraycounts: Array,
  };
  static targets = ["spraycountsContainer"];

  render() {
    const viz = d3.select(this.spraycountsContainerTarget).select("div#viz");
    const TOTAL_COUNT = this.spraycountsValue.reduce((previous, current) => {
      return previous + current.count;
    }, 0);
    viz
      .selectAll("div.row")
      .data(this.spraycountsValue)
      .join((enter) => {
        const row = enter
          .append("div")
          .attr("class", "row w-full h-8 my-1 relative flex items-end");

        row
          .append("div")
          .attr("style", (d, i) => `width: ${(d.count / TOTAL_COUNT) * 100}%;`)
          .attr("class", "border-l-2 border-b-4 border-lightGreen h-full");

        row
          .append("div")
          .text((d, i) => `${d.name}: ${d.count}`)
          .attr("class", "absolute top-1 left-2");
      });
  }

  connect() {
    this.render();
  }
}
