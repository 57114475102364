import { Controller } from "@hotwired/stimulus"

export default class Colorpicker extends Controller {
    static targets = ["colorinput", "radiocontainer"]
    static values = {
        defaultshowradio: Boolean
    }

    toggleInput() {
        const currentShowManual = !this.colorinputTarget.disabled

        if (currentShowManual) {
            this.colorinputTarget.disabled = true
            this.colorinputTarget.classList.add("hidden")

            this.radiocontainerTarget.disabled = false
            this.radiocontainerTarget.classList.remove("hidden")
        } else {
            this.colorinputTarget.disabled = false
            this.colorinputTarget.classList.remove("hidden")

            this.radiocontainerTarget.disabled = true
            this.radiocontainerTarget.classList.add("hidden")
        }
    }

    connect() {
        if (this.defaultshowradioValue === true) {
            return
        }
        // look at all the radio buttons and see if any of them are selected
        const anySelected = Array.from(this.radiocontainerTarget.getElementsByTagName("input"))
            .reduce((selected, input) => {
                return selected || input.checked
            }, false);
        
        if (!anySelected) {
            this.toggleInput()
        }
    }
}